.vendor-packages-container
{

}

.vendor-packages-container .event-info-container
{
    /* overflow: hidden; */
    position: relative;
    border: 1.5px solid #F1B229;
    width: 80%;
    margin: auto;
    border-radius: 11px;
}

.vendor-packages-container .booking-card-box
{
    background-color: #0E2139;
    border-radius: 20px;
    cursor: pointer;
}
.vendor-packages-container .booking-card-box:hover
{
    box-shadow: 0 1px 10px 2px #61758d6c;
}

.vendor-packages-container .booking-card-box .box-days
{
    font-size: 1.8rem;
    font-weight: bold;
}
.vendor-packages-container .booking-card-box .box-price
{
    font-size: 2.3rem;
    font-weight: normal;
}
.vendor-packages-container .booking-card-box .box-anchor
{
    font-size: 1.2rem;
    font-weight: normal;
    text-decoration: none;
    cursor: pointer;
}

.vendor-packages-container .loc-drop-down-container
{
    position: absolute;
    right: 0;

}
.vendor-packages-container .loc-drop-down-container .drop-down-content
{
    background-color: white;
    
    box-shadow: 0 8px 16px rgba(243, 243, 243, 0.2);
    z-index: 1;
    border-radius: 10px;
    padding: 15px 50px;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
}
.vendor-packages-container .loc-drop-down-container .drop-down-content p
{
    color: black;
    font-weight: 500;
    font-size: 1.2rem;
}
.vendor-packages-container .loc-drop-down-container .drop-down-content p:hover
{
    color: #0E2139;
    cursor: pointer;
}

.vendor-packages-container .event-info-container .border-right
{
    border-right:1.5px solid #F1B229;
}
.vendor-packages-container .event-info-container .border-left
{
    border-left:1.5px solid #F1B229;
}

.vendor-packages-container .event-info-container .hide{
    animation: hide .2s ease-in-out forwards;
  }
.vendor-packages-container .event-info-container .show{
    animation: show .2s ease-in-out forwards;
  }
.vendor-packages-container .event-info-container .rotate-icon{
    animation: rotate .3s ease-in-out forwards;
  }

  @keyframes rotate{
    from
    {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(180deg);
    } 
  }

  @keyframes hide{
    from
    {
      display: block;
      opacity: 1;

    }
    to {
      display: none;
      opacity: 0;
    } 
  }
  @keyframes show{
    from
    {
      display: none;
      opacity: 0;

    }
    to {
      display: block;
      opacity: 1;
    } 
  }

@media(max-width:769px)
{
    .vendor-packages-container .event-info-container
    {
        padding:0 10px;
    }
    .vendor-packages-container .event-info-container .border-right
    {
        border: none;
        border-bottom: 1.5px solid #F1B229;
        width: 100%;
    }
    .vendor-packages-container .event-info-container .border-left
    {
        border: none;
        width: 100%;
    }
    .vendor-packages-container .loc-drop-down-container
    {
        right: 0px;
        top: 220px;
        width: 100%;
    }
}