 
  .vendors-all-seats .movie-container {
    /* margin: 20px 0px; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .vendors-all-seats .container {
    perspective: 1000px;
    margin: 40px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .vendors-all-seats .seat {
    user-select: none;
    background-color: #444451;
    height: 40px;
    width: 85px;
    margin: 5px;
    border-radius: 50px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .vendors-all-seats .selected {
    background-color: #0081cb ;
  }

  .vendors-all-seats .prime {
    background-color: #F1B229;
  }

  .vendors-all-seats .regular {
    background-color: #CB317F;
  }

  .vendors-all-seats .stage {
    background-color: #02A1A9;
  }
  
  .vendors-all-seats   .occupied {
    background-color: #fff;
    color: #000;
  }

  .vendors-all-seats .no-place {
    background-color: transparent;
  }
  
  .vendors-all-seats .no-space { 
    background-color: transparent;
    color: transparent;
  }
 
  .vendor-seats-wrapper  .event-info-container .hide{
    animation: hide .2s ease-in-out forwards;
  }
.vendor-seats-wrapper  .event-info-container .show{
    animation: show .2s ease-in-out forwards;
  }
.vendor-seats-wrapper  .event-info-container .rotate-icon{
    animation: rotate .3s ease-in-out forwards;
  }

  .vendor-seats-wrapper .loc-drop-down-container {
    position: relative;
    display: inline-block;
}
  
  .vendor-seats-wrapper .loc-drop-down-container .drop-down-content
{
    background-color: white;
    right: 0%;
    box-shadow: 0 8px 16px rgba(243, 243, 243, 0.2);
    z-index: 1000;
    border-radius: 10px;
    padding: 15px 50px; 
    position: absolute;
    top: 0;
    gap: 10px;
    width: 280px;
}

.vendor-seats-wrapper .loc-drop-down-container .drop-down-content p
{
    color: black;
    font-weight: 500;
    font-size: 1.2rem;
}
.vendor-seats-wrapper .loc-drop-down-container .drop-down-content p:hover
{
    color: #0E2139;
    cursor: pointer;
}
  
  .vendors-all-seats  .seat:not(.occupied):hover,
  .vendors-all-seats  .seat:not(.stage):hover{
    cursor: pointer;
    transform: scale(1.05);
  }
  
  .vendors-all-seats   .showcase .seat:not(.occupied):hover,
  .vendors-all-seats   .showcase .seat:not(.stage):hover {
    cursor: default;
    transform: scale(1.05);
  }
  
  .vendors-all-seats  .showcase {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    background: rgba(0, 0, 0, 0.1);
    padding: 5px 10px;
    border-radius: 5px;
    color: #777;
  }
  
  .vendors-all-seats .showcase li {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
  }
  
  .vendors-all-seats  .showcase li small {
    margin-left: 2px;
  }
  
  .vendors-all-seats .row {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .vendors-all-seats  .screen {
    background: #fff;
    height: 70px;
    width: 70%;
    margin: 15px 0;
    transform: rotateX(-45deg);
    box-shadow: 0 3px 10px rgba(255, 255, 255, 0.7);
  }
  
  .vendors-all-seats  p.text {
    margin: 40px 0;
  }
  
  .vendors-all-seats p.text span {
    color: #0081cb;
    font-weight: 600;
    box-sizing: content-box;
  }
  
  .vendors-all-seats  .credits a {
    color: #fff;
  }

  @media(max-width:769px)
{
    .vendor-seats-wrapper .event-info-container
    {
        padding:0 10px;
    }
    .vendor-seats-wrapper .event-info-container .border-right
    {
        border: none;
        border-bottom: 1.5px solid #F1B229;
        width: 100%;
    }
    .vendor-seats-wrapper .event-info-container .border-left
    {
        border: none;
        width: 100%;
    }
    .vendor-seats-wrapper .loc-drop-down-container
    {
        right: 0px;
        top: 220px;
        width: 100%;
    }
    .vendors-all-seats  .showcase {
      flex-direction: column;
      justify-content: space-between;
      align-items: start;
    }
    .vendor-seats-wrapper .loc-drop-down-container .drop-down-content
    {
      background-color: white;
      right: 30%;
      box-shadow: 0 8px 16px rgba(243, 243, 243, 0.2);
      z-index: 1000;
      border-radius: 10px;
      padding: 15px 50px;
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      top: -222px;
      gap: 10px;
      width: max-content;
    }
}