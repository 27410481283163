.header {
    background: transparent;
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
}

.header .header-wrapper {
    width: 93.5%;
}

.header .header-wrapper .navbar .navbar-container {
    width: 100%;
}
.header .header-wrapper .navbar .navbar-container .nav-logo{
    max-width:100px;
    cursor: pointer;
}

.header .main-nav {
    list-style-type: none;
    margin: 0;
}

.header .nav-links,
.logo {
    text-decoration: none;
    color:  #ffffff;
    font-size: .9rem;
}

.header .nav-links:active,
.logo:active {
    color: #ffffff;
    font-weight: 500;
}

.header .nav-links:focus,
.logo:focus {
    color: #ffffff;
    font-weight: 500;
}

.header .main-nav li {
    text-align: center;
    color: white;
}
.header .nav-list .nav-links
{
    font-weight: 600;
    cursor: pointer;
    color: white;
    transition: .3s;
}
.header .nav-list .nav-links:hover
{
    color: #F1B229;
}

.header .extras-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}
.header .responsive-popup-menu
{
    z-index: 1;
    position: absolute;
    top: 80px;
    background-color: rgb(255, 255, 255);
    color: black;
}

.header .responsive-menu-btn img
{
    width: 25px;
    height: 25px;
}



.header .extras-btns .blue-btn
{
    background-color: #283285;
    color: white;
    border-radius: 20px;
    border: none;
    padding: 5px 20px;
    font-size: .9rem;
    transition: .3s;
}
.header .extras-btns .blue-btn:hover{
    background-color: #20276a;
    cursor: pointer;
}
.header .extras-btns .blue-btn img {
    width: 17px;
    margin-right: 3px;
}

.header .resp-nav-list .nav-links 
{
    color: black;
}

.header .resp-nav-list li{
    list-style: none;
}
.header .resp-nav-list
{
    width: 100%;
}


.navigation-dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-trigger {
    cursor: pointer;
    color: white;
  }
  
  .dropdown-content {
    position: absolute;
    top: 40px;
    left: -37%;
    background-color: white;
    min-width: max-content;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 10px;
    padding: 10px 20px;
     display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
  }

  .dropdown-content .nav-links{
    color: rgb(43, 43, 43)!important;
    list-style: none;
    text-decoration: none;
  }
  .dropdown-content .nav-links:hover{
    color: #F1B229!important;
  }
  
  
  /* Custom CSS Animation */
  .dropdown-content-enter {
    opacity: 0;
    transform: translateY(-10px);
  }
  
  .dropdown-content-enter-active {
    display: block;
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms, transform 300ms;
  }
  
  .dropdown-content-exit {
    opacity: 1;
    transform: translateY(0);
  }
  
  .dropdown-content-exit-active {
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 300ms, transform 300ms;
  }

  .header .arrow-icon
  {
    width: 15px;
    height: 15px;
    margin-left: 5px;
  }
  
  .header .rotate-icon {
    animation: rotate180 .3s forwards;
  }

  
  @keyframes rotate180 {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(180deg);
    }
  }
  
  /* Return to original position */
  .header .rotate-back {
    animation: rotateBack .1s forwards;
  }
  
  @keyframes rotateBack {
    from {
      transform: rotate(180deg);
    }
    to {
      transform: rotate(0deg);
    }
  }

  .header .nav-list .none{
    display: none;
  }
  .header .nav-list .open{
    display: flex;
  }