.popular-blog-container
{

}

.popular-blog-container .body-heading-b
{
    font-size: 2rem;
}

.popular-blog-container .blog-card .blog_list_logo
{
    gap: 10px;
}
.popular-blog-container .blog-card .blog_list_logo_img
{
    width: 60px;
    height: 60px;
}

.popular-blog-container .blog-card .blog_list_logo_title
{
    font-size: 1.4rem;
    color: #848484;
    font-weight: 600;
}

.popular-blog-container .blog-card .blog_list_logo_date
{
    font-size: .9rem;
    color:#8D8D8D;
}
.popular-blog-container .blog-card .blog_list_logo_date span
{
    margin-left: 5px;
    font-weight: 600;
}

.popular-blog-container .blog-card .card-anchor
{
    text-decoration: none;
    font-weight: 700;
    cursor: pointer;
}

.popular-blog-container .blog-card .color-blue
{
    color:#2245D1;
}

.popular-blog-container .blog-card .card-img
{
    border-radius: 10px;
    max-height: 350px;
}

.popular-blog-container .popular-card .card-title
{
    color: white;
    font-size: 1.2rem;
    font-weight: 500;
}

.popular-blog-container .blog-card .blog_list_logo_date .card-date
{
    font-size: .9rem;
    color:#8D8D8D;
}

.popular-blog-container .popular-card
{
    cursor: pointer;
}

.popular-blog-container .popular-card .popular-blog-img
{
    max-width: 200px;
    height: 150px;
    border-radius: 10px;
}

.popular-blog-container .blog-card .blog-read-btn
{
    border-radius: 20px;
    background-color: #02A0A9;
    color: white;
    border: none;
    padding:5px 15px;
    /* font-size: .7rem; */
    transition: .3s;
}
.popular-blog-container .blog-card .blog-read-btn:hover
{
    background-color: #03666b;

}

.popular-blog-container .page-btn-container button
{
    padding:7px 14px;
    border: 1px solid #B9B9B9;
    background-color: white;
    color: #272525;
    box-shadow: 0px 10px 15px 0px rgba(0,0,0,0.1);
}

.popular-blog-container .page-btn-container button .next-icon
{
    width: 10px;
    height: 10px;
    margin: 0;
}
.popular-blog-container .page-btn-container .active
{
    background-color: #C4C4C4;
}
.popular-blog-container .blog-list::-webkit-scrollbar {
  width: 0em; 
}

.popular-blog-container .blog-list::-webkit-scrollbar-thumb {
  background-color: #888; 
}