.footer-container
{
    background-color: #040910;
    padding-top: 30px;
    color: white;
}

.footer-container .line-seprator
{
    border: 1px solid rgba(255, 255, 255, 0.457);
    width: 90%;
    margin: auto;
    margin-top:50px;
}

/* Upper container */

.footer-container .upper-newsletter-container .page-links a
{
    text-decoration: none;
    color: #F1B229;
    cursor: pointer;
}

.footer-container .upper-newsletter-container .social-link{
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 20px;
}

.footer-container .upper-newsletter-container .form-container h5{
    color: #02A1A9;
    font-weight: 600;
}

.footer-container .upper-newsletter-container .newsletter-form
{
    display: flex;
    align-items: center;
}
.footer-container .upper-newsletter-container .newsletter-form input
{
    border: none;
    background: transparent;
    border: 1px solid #02A1A9;
    border-radius: 30px;
    padding:15px 15px;
    color: white;
    width:25em;
}
.footer-container .upper-newsletter-container .newsletter-form input:focus
{
    outline: none;
}
.footer-container .upper-newsletter-container .newsletter-form button
{
    display: flex;
    align-items: center;
    gap: 10px;
    border: none;
    position: relative;
    right: 15%;
    border-radius: 30px;
    background-color: #02A1A9;
    color: white;
    padding:14px 15px;
    font-weight: 700;
    font-size: 1.25rem;
}

.footer-container .upper-newsletter-container .newsletter-form button img{
    width: 15px;
}

.footer-container .upper-newsletter-container .newsletter-form button:hover{
    cursor: pointer;
    background-color: #1898d8;
}

.footer-container .upper-newsletter-container .text-container .text-content p,
.footer-container .upper-newsletter-container .form-container p
{
    font-size: small;
}
.footer-container .upper-newsletter-container .text-container .text-content h3
{
    margin-bottom: 5px;
    font-weight: 700;
    font-size: 1.3rem;
    color: white;
}
.footer-container .upper-newsletter-container .social-link img{
    width: 25px;
    height: 25px;
}

/* Lower Container */

.footer-container .lower-container
{
    display: flex;
    align-items: center;
    text-align: center;
    padding:20px 68px;
}
.footer-container .lower-container p
{
   font-size: .8rem;
}
.footer-container .lower-container p a
{
   font-size: .8rem;
   color: white;
   text-decoration: none;
}


@media(max-width : 1050px)
{
    .footer-container .upper-newsletter-container
    {
        flex-direction: column;
    }
    .footer-container .upper-newsletter-container .text-container
    {
        flex-direction: column;
    }
    .footer-container .upper-newsletter-container .text-container,.newsletter-form
    {
        margin: auto;
        margin-bottom: 15px;
    }
    .footer-container .upper-newsletter-container .newsletter-form button
    {
        right: 50px;
    }
    
}

@media (max-width: 730px) {
    
    .footer-container .upper-newsletter-container .newsletter-form
    {
        justify-content: center;
        width: 70%;
        position: relative;
    }
    .footer-container .upper-newsletter-container .newsletter-form input
    {
        width: 100%;
        padding: 7px 10px;
        margin: auto;

    }
    .footer-container .upper-newsletter-container .newsletter-form button
    {
        padding:5px 10px;
        position: absolute;
        right:0;
    }
    .footer-container .lower-container
    {
        padding:10px 5px;
        text-align: center;
    }
    
}

@media(max-width : 768px)
{
    .footer-container .upper-newsletter-container .text-container{
        
        text-align: center;
    }
    .footer-container .upper-newsletter-container .social-link{
        justify-content: center;
    }
    .footer-container .upper-newsletter-container .form-container
    {
        display: flex;
        text-align: center;
        flex-direction:column ;
        justify-content: center;
        align-items: center;
    }
    .footer-container .line-seprator
    {
        margin-top: 20px;
    }
    .footer-container .lower-container p
    {
        font-size: .6rem;
    }
}

@media(max-width : 400px)
{
    .footer-container .upper-newsletter-container .newsletter-form button
    {
        padding:8px 10px;
        font-size: .9rem;
    }
}