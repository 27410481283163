.event-container
{
    background-color: #060E19;
    text-align: center;
}

.event-container .event-box
{
    width: 30em;
    text-align: center;
}

.event-container .event-box h3{
    color: #F1B229;
    cursor: pointer;
    font-weight: 600;
}
.event-container .event-box h3:hover{
    color: #cc951f;
}

.event-container .line-seprater
{
    width : 100%;
    margin: auto;
    border: 1px solid rgba(255, 255, 255, 0.238);
}

@media(max-width : 600px)
{
    .event-container .event-box
    {
        width: auto;
    }
}