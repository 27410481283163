.up-event-container
{

}

.up-event-container .event-box-container
{
    border-width: 2px;
    border-style: solid;
    border-radius: 20px;
}

.event-box-container .upper-event-img
{
    width: 120px;
    height: 60px;
    border-radius: 10px;
}

.event-box-container .date-container h2{
    color: white;
    font-size: 2.5rem;
    font-weight: 700;
}
.event-box-container .date-container h4{
    color: white;
}
.event-box-container .event-details h6
{
    color: white;
    font-size: 1.2rem;
}
.event-box-container .btn-pink
{
    background-color:#CB317F;
    border-color:#CB317F;
}
.event-box-container .btn-yellow
{
    background-color:#F1B229;
    border-color:#F1B229;
}
.up-event-container .border-pink
{
    border-color:#CB317F;
}
.up-event-container .border-yellow
{
    border-color:#F1B229;
}
.up-event-container .border-blue
{
    border-color:#02A1A9;
}
