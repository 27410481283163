.Testimonials-container
{
    background-color: #060E19;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Testimonials-container .testimonial-card
{
    cursor: pointer;

}
.Testimonials-container .testimonial-card .arrow-img
{
    width:45px;
}


.Testimonials-container .testimonial-card .testimonial-card-info
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}


.Testimonials-container .profile-info
{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.Testimonials-container .profile-info img{
    border-radius: 50%;
    width: 85px;
}

.Testimonials-container .profile-info .review-text
{
    color: #F8FBFB;
    font-size: 14px;
    margin-top: 30px;
    margin-bottom: 30px;

}
.Testimonials-container .profile-info .name
{
    color: #E1A92A;
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 10px;
}