 
.login-user-wrapper .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    overflow: hidden;
    justify-content: center;
    z-index: 999;
  }
  
  .login-user-wrapper .popup-inner {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  .login-user-wrapper .form-group {
    margin-bottom: 15px;
  }
  
  .login-user-wrapper label {
    display: block;
    margin-bottom: 5px;
  }
  
  .login-user-wrapper  input {
    width: 100%;
    padding: 8px;
    margin-top: 3px;
    box-sizing: border-box;
  }
  
  .login-user-wrapper  button {
    background-color: #070707;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .login-user-wrapper .red-asterik {color: red;}