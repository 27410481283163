.sponsor-container
{
    text-align: center;
    background-image: url("../../../public/images/creative/sponsor-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 10em;
    padding-bottom: 10em;
}

.sponsor-container .companies-logo-box
{
    display: flex;
    padding-top: 3rem;
    padding-bottom: 3rem;
}
 
.sponsor-container .companies-logo-box img{
    width: 180px;
}
