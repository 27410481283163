.Portfolio-container
{
    background-color: #060E19;
    color: white;
    text-align: center;
    padding: 3em 0;
}

.Portfolio-container .images-gallery
{
    margin-top: 50px;
}

.Portfolio-container .swiper-images
{
    width: 80%;
    height: 300px;
    border-radius: 10px;
}


.Portfolio-container .images-gallery img{
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.Portfolio-container .portfolio-para
{
    width: 70%;
    display: block;
    margin: auto;
}

.Portfolio-container .blue-btn 
{
    margin-top: 80px;
}


@media (max-width: 400px)
{
    
} 