.floor-plan-container
{

}

.floor-plan-container .title
{
    font-size: 2.3rem;
    font-weight: normal;
    color: white;
}