.text-content-section
{
    text-align: center;
    background-color: #000914;
}

.text-content-section .container-space
{
  width: 90%;
  margin: auto;
}

.text-content-section .paddLeft
{
    padding-left: 5%;
}

.text-content-section .line-seprator
{
    border: 1px solid #c1c0c0;
    width: 100%;
    margin: auto;

}

.text-content-section .text-btn
{
  padding: 0.6rem 2rem;
  font-size: 16px;
  background: transparent;
  border: 1px solid #8740A5;
  font-weight: 400;
  color: #8740A5;
  font-weight: bold;
  border-radius: 25px;
  transition: .3s;
}
.text-content-section .text-btn:hover
{
  background: #8740A5;
  border: 1px solid #8740A5;
  color: white;
}