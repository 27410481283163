.countdown-container
{

}

.countdown-container .title
{
    font-weight: 600;
}

.countdown-container .count
{
    width: fit-content;
    margin: auto;
}

.countdown-container .strokeText {
    font-size: 80px;
    font-weight: 700;
    font-family: 'Bebas Neue' ,sans-serif;
    -webkit-text-stroke: 1px white;
    -webkit-text-fill-color: transparent;  
  }

@media(max-width:500px)
{
    .countdown-container .strokeText {
        font-size: 50px;
       
      }
}