.event-box-container
{
    background-color: #060E19;
    color: white;
}

.event-box-container .list-bullet
{
    margin-top: 2px;
    margin-right: 3px;
    width: 15px;
    height: 15px;
}

.event-box-container .list-para
{
    font-size: 14px;
    margin-left: 4px;
}

.event-box-container .img-container
{
    position: relative;
}
.event-box-container .img-container-reverse
{
    position: relative;
    margin-left: 20px;
}

.event-box-container .content-img
{
    border-radius: 20px;
    width: 80%;
    position: relative;
    z-index: 10;
}

.event-box-container .body-heading
{
    font-size: 1.8rem;
}

.event-box-container .img-background
{
    position: absolute;
    height: 100%;
    width: 80%;
    top: -20px;
    left: 20px;
    border-radius: 20px;
    border-width: 2px;
    border-style: solid;
    z-index: 0;
}
.event-box-container .img-background-reverse
{
    position: absolute;
    height: 100%;
    width: 80%;
    top: -20px;
    left: 20px;
    border-radius: 20px;
    border-width: 2px;
    border-style: solid;
    z-index: 0;
}

@media(max-width:992px)
{
    .event-box-container .icon-list
    {
        justify-content: center;
    }
    .event-box-container .img-container
    {
        padding-left: 0px;
    }
    .event-box-container .img-container-reverse
    {
        margin-left: 0px;
    }
}