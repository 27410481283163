.main {
  padding: 120px 0px 120px 0px;
  width: 100%;
  background-color: #121315;
}
.main h2,
.main h1 {
  text-align: center;
}
.box {
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.imgCont {
  width: 40%;
  z-index: 1;
}
.imgCont img {
  width: 480px;
}
.content {
  width: 65%;
  background-color: #191919;
  border-radius: 12px;
  padding: 50px 30px 50px 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.content p,
.content h3,
.content h5,
.content button {
  align-self: flex-start;
  text-align: start;
}
.content h3 {
  font-weight: 400;
}
.content p {
  color: white;
}
.content h5 {
  color: #848484;
}
@media (min-width: 1400px) {
  .imgCont img {
    width: 550px;
  }
}
@media (max-width: 1200px) {
  .imgCont img {
    width: 410px;
  }
}
@media (max-width: 992px) {
  .main {
    padding: 60px 0px 60px 0px;
  }
  .box {
    flex-direction: column;
  }
  .imgCont {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .imgCont img {
    width: 100%;
  }
  .content {
    width: 100%;
    padding: 40px 25px 40px 25px;
  }
}
