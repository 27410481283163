.common_hero_section {
  color: white;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 40em;
  z-index: 10;
}


.common_hero_section .hero_content_wrapper {
  width: 85%;
  margin-top: 6em;
}

.common_hero_section .bg-effect
{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient( #060e197d, #060e195b,#060e194e,#060e19);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  z-index: -2;
}
.common_hero_section .bg-effect-overlay
{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color:rgba(0, 0, 0, 0.309) ;
  z-index: -2;
}

.common_hero_section .service-form
{
  background-color: rgba(243, 241, 241, 0.262);
  backdrop-filter: blur(5px);
  border-radius: 10px;
}
.common_hero_section .service-form .service-from-btn
{
  background-color: #02A0A9;
  color: white;
  width: fit-content;
  padding: 5px 39px;
  border: none;
  border-radius: 30px;
  margin-top: 35px;
}
.common_hero_section .service-form input
{
  border: none;
  border-bottom: 1px solid grey;
  margin-top: 20px;
  background: transparent;
  color: white;
  padding-bottom: 5px;

}
.common_hero_section .service-form input:focus
{
  outline: none;
}

.common_hero_section .date-container
{
  border: 2px solid white;
  padding: 10px 15px;
}