.about-text-container
{
    background-color: #060E19;
}

.about-text-container .about-icon
{
    width: 100px;
    height: 100px;
}

.about-text-container .body-paragraph
{
    font-size: 14px;
}