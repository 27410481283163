.CardTestimonial-container
{
    background-color: #060E19;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

}
.CardTestimonial-container .card-box
{
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap:6px;
    cursor: pointer;
    height: 100%;
}
.CardTestimonial-container .swiper-slide
{
    
    height: auto;
}

.CardTestimonial-container .card-box .card-text-content
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2px;
    /* margin: auto; */
    margin-top: 10px;
    height: 90%;
    text-align: center;
}

.CardTestimonial-container .card-box h5{
    color:white ;
    font-size: 1.6rem;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: 500;
}

.CardTestimonial-container .card-box p{
    
    width: 93%;
    margin-top: auto;
    margin-bottom: 2px;
}

.CardTestimonial-container .card-box a
{
    color: #F1B229;
    text-decoration: none;
    transition: .3s;

}

.CardTestimonial-container .card-box a:hover{
    cursor: pointer;
    color:#eaa615;
}

.CardTestimonial-container .card-box img{
    width: 100%;
    max-height: 300px;
    margin:5% auto;
    margin-bottom: 10px;
}

.CardTestimonial-container .card-list
{
    margin: auto;
    width: 100%;
}

.CardTestimonial-container .genral-button
{
    margin-top: 20px;
}