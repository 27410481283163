* {
  margin: 0; 
  padding: 0;
  scroll-behavior: smooth; 
  /* For heading  */
  /* font-family: 'Bebas Neue', sans-serif;  */
  /* For genral content */
  font-family: 'Rubik', sans-serif;
}
  
/* 
  Headings/Sub-Headings/Paragraphs/ Fonts Standard Spacing Fonts along with media queries,
*/

.font-bebas
{
  font-family: 'Bebas Neue', sans-serif;
}

.standard-margin-space {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.standard-padding-space {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.body-heading, .hero-section-heading { 
  font-weight: 600;
  text-transform: uppercase;
  font-family: 'Bebas Neue', sans-serif;
}
.body-heading span, .hero-section-heading span{ 
  font-weight: 600;
  text-transform: uppercase;
  font-family: 'Bebas Neue', sans-serif;
}

.body-heading { color: white; font-size: 40px; letter-spacing: 1px;}

.hero-section-heading  { font-size: 70px; color: white; letter-spacing: 4px; font-family:'Bebas Neue', sans-serif; }
.hero-section-heading span { font-family:'Bebas Neue', sans-serif;}
  
.body-paragraph { 
  font-weight: 400;
  font-size: 16px;
  color: white;
}

.color-pink
{
  color:#CB317F;
}

.color-yellow
{
  color: #F1B229;
}

.color-blue
{
  color: #02A1A9;
}
  
.genral-btn{ 
  padding: 0.4rem 2rem;
  font-size: 18px;
  background: transparent;
  border: 1px solid #02A1A9;
  font-weight: 400;
  color: #02A1A9;
  letter-spacing: 1px;
  border-radius: 20px;
  transition: .3s;
}
.genral-btn:hover{ 
  background-color: #086065;
}
.genral-btn-plain{ 
  padding: 0.4rem 2rem;
  font-size: 18px;
  background-color: #F1B229;
  border: 1px solid #F1B229;
  font-weight: 400;
  color: #060E19;
  letter-spacing: 1px;
  border-radius: 20px;
  transition: .3s;
}
.genral-btn-plain:hover{ 
  background-color: #c59120;
}

.sub-heading
{
  font-size: 1.5rem;
  color: white;
  font-weight: 600;
}

.genral-btn span {
  margin-right: .4rem;
}

/* Style for the actual popup message */
.popup-message-success {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background: #09d410; 
  color: #ffffff;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 9999;  
}

.popup-message-err {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background: #ee4343;
  color: #ffffff;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 9999;  
}

@media (max-width: 1250px) {
  .body-heading  {
    font-size: 37px;
  }

  .hero-section-heading {
    font-size: 50px;
  }
}

@media (max-width: 460px) {
  .body-heading  {
    font-size: 30px;
  }
 
  .hero-section-heading  {
    font-size: 40px;
  }

  .body-paragraph {
    font-size: 15px;
  }
}

@media (max-width: 380px) {
  .body-heading  {
    font-size: 27px;
  }

  .hero-section-heading  {
    font-size: 32px;
  }

  .body-paragraph {
    font-size: 14px;
  }

  .sub-heading {  
    /* font-size: 12px;  */
  } 
}